import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from "../../components";
import Block from "../../components/cta.js";
import VizPage from "../../components/vizPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <VizPage heading="City of Milwaukee Eviction Filings" mdxType="VizPage">
        <iframe src="https://public.tableau.com/views/MilwaukeeEvictionFilings/EvictionsFiled?:showVizHome=no&:embed=true" width="1015" height="835"></iframe>
        <br></br>
        <h2>{`Eviction filing`}</h2>
        <br></br>
        <p>{`Represents a court action typically filed by a landlord or property manager to begin eviction proceedings.`}</p>
        <br></br>
        <p><strong parentName="p">{`Eviction Records`}</strong></p>
        <br></br>
        <p>{`Eviction data is pulled from the WCCA REST Interface — programmatic access to Wisconsin Circuit Court records. Small claims cases in Milwaukee County with a case type of small claims eviction are pulled down and stored on a weekly basis. CCAP provides no warranties as to the accuracy or timeliness of the information contained in the WCCA Data.`}</p>
      </VizPage>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      